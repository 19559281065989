import React, { createContext, useState } from "react";
import axios from "axios";
import baseurl from "../services/baseurl";

export const EmailContext = createContext();

export const EmailContextProvider = ({ children }) => {
    const token = 'D8ADD116-5203-4571-B3D3-73ECC007A13E'

    const [msgSucessoEmail, setMsgSucessoEmail] = useState('')
    const [msgErroEmail, setMsgErroEmail] = useState('')
    const [loading, setLoading] = useState("")

    // const [nome, setNome] = useState('')
    // const [email, setEmail] = useState('')
    // const [captcha, setCaptcha] = useState('')
    // const [produto, setProduto] = useState('')
    // const [phone, setPhone] = useState('')
    const [check, setCheck] = useState(false)

    const [nomeContato, setNomeContato] = useState('')
    const [emailContato, setEmailContato] = useState('')
    const [ddd, setDdd] = useState('')
    const [telefone, setTelefone] = useState('')
    const [cidade, setCidade] = useState('')
    const [uf, setUf] = useState('')
    const [origem, setOrigem] = useState('')
    const [contabilidade, setContabilidade] = useState('')
    const [pdf, setPdf] = useState('')
    const [representante, setRepresentante] = useState('')

    const [cnpj, setCnpj] = useState('')
    const [site, setSite] = useState('')
    const [nomeDiretor, setNomeDiretor] = useState('')
    const [emailDiretor, setEmailDiretor] = useState('')
    const [telefoneContato, setTelefoneContato] = useState('')
    const [numColaborador, setNumColaborador] = useState('')

    // const FalarEspecialista = async (origem) => {
    //     setLoading(true)
    //     let data = {}
    //     let endPoint = ''
    //     if (nome === '') {
    //         setLoading(false)
    //         setMsgErroEmail('Nome é obrigatório.')
    //         setTimeout(() => {
    //             setMsgErroEmail("");
    //         }, 6000);
    //         return
    //     }
    //     if (email === '') {
    //         setLoading(false)
    //         setMsgErroEmail('E-mail é obrigatório.')
    //         setTimeout(() => {
    //             setMsgErroEmail("");
    //         }, 6000);
    //         return
    //     }
    //     if (phone === '') {
    //         setLoading(false)
    //         setMsgErroEmail('Telefone é obrigatório.')
    //         setTimeout(() => {
    //             setMsgErroEmail("");
    //         }, 6000);
    //         return
    //     }
    //     if (origem == "Soluções") {
    //         if (captcha === '') {
    //             setLoading(false)
    //             setMsgErroEmail('Resolva o captcha.')
    //             setTimeout(() => {
    //                 setMsgErroEmail("");
    //             }, 6000);
    //             return
    //         }
    //         if (captcha !== '9') {
    //             setLoading(false)
    //             setMsgErroEmail('Valor incorreto.')
    //             setTimeout(() => {
    //                 setMsgErroEmail("");
    //             }, 6000);
    //             return
    //         }
    //         data = {
    //             email: email,
    //             nome: nome,
    //             origem: origem,
    //             produto: produto,
    //             telefone: phone
    //         }
    //         endPoint = 'FalarEspecialistaAnexo'
    //     } else {
    //         data = {
    //             email: email,
    //             nome: nome,
    //             origem: origem,
    //             telefone: phone
    //         }
    //         endPoint = 'FalarEspecialista'
    //     }
    //     console.log("produto ", produto)
    //     console.log("endPoint ", endPoint)
    //     return axios({
    //         url: baseurl + 'Email/' + endPoint,
    //         method: 'POST',
    //         headers: { Token: `${token}` },
    //         data: data
    //     })
    //         .then((response) => {
    //             console.log(response)
    //             setLoading(false)
    //             setEmail('')
    //             setNome('')
    //             setCaptcha('')
    //             setPhone('')
    //             setProduto('')
    //             setMsgSucessoEmail('E-mail enviado com sucesso!')
    //             setTimeout(() => {
    //                 setMsgSucessoEmail("");
    //             }, 6000);
    //             return Promise.resolve()
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //             setLoading(false)
    //             setMsgErroEmail('Erro ao enviar o e-mail!')
    //             setTimeout(() => {
    //                 setMsgErroEmail("");
    //             }, 6000);
    //             return Promise.reject(error)
    //         })
    // }

    const FaleConosco = async (origem) => {
        setLoading(true)
        const data = {
            nome: nomeContato,
            email: emailContato,
            telefone: ddd + telefone,
            cidade: cidade,
            uf: uf,
            origem: origem,
            contabilidade: contabilidade,
            produto: pdf,
            representante: representante
        }
        if (typeof contabilidade === 'undefined' || contabilidade === '') {
            setLoading(false)
            setMsgErroEmail("Campo assunto obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof nomeContato === 'undefined' || nomeContato === '') {
            setLoading(false)
            setMsgErroEmail("Campo nome obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof emailContato === 'undefined' || emailContato === '') {
            setLoading(false)
            setMsgErroEmail("Campo e-mail obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof ddd === 'undefined' || ddd === '') {
            setLoading(false)
            setMsgErroEmail("Campo DDD obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof telefone === 'undefined' || telefone === '') {
            setLoading(false)
            setMsgErroEmail("Campo telefone obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof cidade === 'undefined' || cidade === '') {
            setLoading(false)
            setMsgErroEmail("Campo cidade obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof uf === 'undefined' || uf === '') {
            setLoading(false)
            setMsgErroEmail("Campo UF obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (!check) {
            setLoading(false)
            setMsgErroEmail("Aceitar Política de Privacidade é obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        return axios({
            url: baseurl + 'Email/FaleConosco',
            method: 'POST',
            headers: { Token: `${token}` },
            data: data
        })
            .then((response) => {
                setLoading(false)
                setNomeContato('')
                setEmailContato('')
                setDdd('')
                setTelefone('')
                setCidade('')
                setUf('')
                setOrigem('')
                setContabilidade('')
                setPdf('')
                setRepresentante('')
                setCheck(false)
                setMsgSucessoEmail('E-mail enviado com sucesso!')
                setTimeout(() => {
                    setMsgSucessoEmail("");
                }, 6000);
                return Promise.resolve()
            })
            .catch((error) => {
                setLoading(false)
                return Promise.reject(error)
            })
    }

    const ContatoCadastro = async () => {
        setLoading(true)
        const data = {
            cnpj: cnpj,
            siteEmpresa: site,
            nomeDiretor: nomeDiretor,
            emailDiretor: emailDiretor,
            telefoneContato: telefoneContato,
            numeroColaboradores: numColaborador
        }
        if (typeof cnpj === 'undefined' || cnpj === '') {
            setLoading(false)
            setMsgErroEmail("Campo CNPJ da empresa obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof site === 'undefined' || site === '') {
            setLoading(false)
            setMsgErroEmail("Campo Site da Empresa obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof nomeDiretor === 'undefined' || nomeDiretor === '') {
            setLoading(false)
            setMsgErroEmail("Campo Nome do Diretor Financeiro obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof emailDiretor === 'undefined' || emailDiretor === '') {
            setLoading(false)
            setMsgErroEmail("Campo E-mail do Diretor Financeiro obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof telefoneContato === 'undefined' || telefoneContato === '') {
            setLoading(false)
            setMsgErroEmail("Campo Telefone de Contato do RH obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        if (typeof numColaborador === 'undefined' || numColaborador === '') {
            setLoading(false)
            setMsgErroEmail("Campo Número de Colaboradores CLT obrigatório")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        return axios({
            url: baseurl + 'Email/ContatoCadastro',
            method: 'POST',
            headers: { Token: `${token}` },
            data: data
        })
            .then((response) => {
                setLoading(false)
                setCnpj('')
                setSite('')
                setNomeDiretor('')
                setEmailDiretor('')
                setTelefoneContato('')
                setNumColaborador('')
                setMsgSucessoEmail('E-mail enviado com sucesso!')
                setTimeout(() => {
                    setMsgSucessoEmail("");
                }, 6000);
                return Promise.resolve()
            })
            .catch((error) => {
                setLoading(false)
                return Promise.reject(error)
            })
    }

    let data = {
        msgSucessoEmail, setMsgSucessoEmail,
        msgErroEmail, setMsgErroEmail,
        loading, setLoading,
        // FalarEspecialista,
        FaleConosco,
        // nome, setNome,
        // email, setEmail,
        // captcha, setCaptcha,
        // phone, setPhone,
        nomeContato, setNomeContato,
        emailContato, setEmailContato,
        ddd, setDdd,
        telefone, setTelefone,
        cidade, setCidade,
        uf, setUf,
        origem, setOrigem,
        contabilidade, setContabilidade,
        pdf, setPdf,
        representante, setRepresentante,
        // produto, setProduto,
        check, setCheck,
        ContatoCadastro,
        cnpj, setCnpj,
        site, setSite,
        nomeDiretor, setNomeDiretor,
        emailDiretor, setEmailDiretor,
        telefoneContato, setTelefoneContato,
        numColaborador, setNumColaborador
    }

    return (
        <EmailContext.Provider value={data}>
            {children}
        </EmailContext.Provider>
    )
}
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom'
import { CompraContext } from '../context/CompraContext';
import Home from "../pages/Home/Home";
import PlanosAssinatura from "../pages/PlanosAssinatura/PlanosAssinatura";
import PlanoAcademico from "../pages/Planos/PlanoAcademico/PlanoAcademico";
import PlanoFit from '../pages/Planos/PlanoFit/PlanoFit';
import PlanoPlus from '../pages/Planos/PlanoPlus/PlanoPlus';
import PlanoEmpresarial from '../pages/Planos/PlanoEmpresarial/PlanoEmpresarial';
import PlanoMaxx from '../pages/Planos/PlanoMaxx/PlanoMaxx';
import Contabil from "../pages/Contabil/Contabil";
import Folha from "../pages/Folha/Folha";
import Fiscal from "../pages/Fiscal/Fiscal";
import Patrimonial from "../pages/Patrimonial/Patrimonial";
import Methodus from "../pages/Methodus/Methodus";
import Eficatus from "../pages/Eficatus/Eficatus";
import Busca from "../pages/Busca/Busca";
import Five from "../pages/Five/Five";
import ERP from "../pages/ERP/ERP";
import Institucional from "../pages/Institucional/Institucional";
import PoliticaPrivacidade from "../pages/PoliticaPrivacidade/PoliticaPrivacidade";
import PoliticaCookies from "../pages/PoliticaCookies/PoliticaCookies";
import PoliticaGarantia from '../pages/PoliticaGarantia/PoliticaGarantia';
import PoliticaReembolso from '../pages/PoliticaReembolso/PoliticaReembolso';
import Solucoes from "../pages/Solucoes/Solucoes";
import Contato from "../pages/Contato/Contato";
import GovernancaCadastral from "../pages/GovernancaCadastral/GovernancaCadastral";
import ComplianceFiscal from '../pages/ComplianceFiscal/ComplianceFiscal';
import RecuperacaoTributaria from '../pages/RecuperacaoTributaria/RecuperacaoTributaria';
import ExclusaoICMS from '../pages/ExclusaoICMS/ExclusaoICMS';
import CertificadoHome from '../pages/Certificado/CertificadoHome/CertificadoHome';
import CertificadoSaibaMais from '../pages/Certificado/CertificadoSaibaMais/CertificadoSaibaMais';
import CertificadoSaibaMaisCnpj from '../pages/Certificado/CertificadoSaibaMais/CertificadoSaibaMaisCnpj';
import CertificadoSaibaMaisIcp from '../pages/Certificado/CertificadoSaibaMais/CertificadoSaibaMaisIcp';
import CertificadoSaibaMaisNfe from '../pages/Certificado/CertificadoSaibaMais/CertificadoSaibaMaisNfe';
import CertificadoSaibaMaisCte from '../pages/Certificado/CertificadoSaibaMais/CertificadoSaibaMaisCte';
import CertificadoDuvidas from '../pages/Certificado/CertificadoDuvidas/CertificadoDuvidas';
import Cadastro from '../pages/Cadastro/Cadastro';
import Compra from '../pages/Compra/Compra';
import CompraFinalizada from '../pages/Compra/CompraFinalizada';
import ExactusBank from '../pages/ExactusBank/ExactusBank';
import SimplesNacional from '../pages/SimplesNacional/SimplesNacional';

function Rotas() {
    const { ProdutoCompra } = useContext(CompraContext)
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/simplesnacionalfacil" element={<SimplesNacional />} />
            <Route path="/planos-e-assinatura" element={<PlanosAssinatura />} />
            <Route path="/plano-academico" element={<PlanoAcademico />} />
            <Route path="/plano-fit" element={<PlanoFit />} />
            <Route path="/plano-plus" element={<PlanoPlus />} />
            <Route path="/plano-empresarial" element={<PlanoEmpresarial />} />
            <Route path="/plano-maxx" element={<PlanoMaxx />} />
            <Route path="/contabil" element={<Contabil />} />
            <Route path="/folha-de-pagamento" element={<Folha />} />
            <Route path="/fiscal" element={<Fiscal />} />
            <Route path="/patrimonial" element={<Patrimonial />} />
            <Route path="/methodus" element={<Methodus />} />
            <Route path="/eficatus" element={<Eficatus />} />
            <Route path="/busca" element={<Busca />} />
            <Route path="/five" element={<Five />} />
            <Route path="/erp" element={<ERP />} />
            <Route path="/institucional" element={<Institucional />} />
            <Route path="/politica-de-privacidade" element={<PoliticaPrivacidade />} />
            <Route path="/politica-de-cookies" element={<PoliticaCookies />} />
            <Route path="/politica-de-reembolso" element={<PoliticaReembolso />} />
            <Route path="/politica-de-garantia" element={<PoliticaGarantia />} />
            <Route path="/solucoes" element={<Solucoes />} />
            <Route path="/contato" element={<Contato />} />
            <Route path="/exactus-bank" element={<ExactusBank />} />
            <Route path="/governanca-cadastral" element={<GovernancaCadastral />} />
            <Route path="/compliance-fiscal" element={<ComplianceFiscal />} />
            <Route path="/recuperacao-tributaria" element={<RecuperacaoTributaria />} />
            <Route path="/exclusao-icms" element={<ExclusaoICMS />} />
            <Route path="/certificado" element={<CertificadoHome />} />
            <Route path="/certificado-saiba-mais/cpf" element={<CertificadoSaibaMais />} />
            <Route path="/certificado-saiba-mais/cnpj" element={<CertificadoSaibaMaisCnpj />} />
            <Route path="/certificado-saiba-mais/icp" element={<CertificadoSaibaMaisIcp />} />
            <Route path="/certificado-saiba-mais/nfe" element={<CertificadoSaibaMaisNfe />} />
            <Route path="/certificado-saiba-mais/cte" element={<CertificadoSaibaMaisCte />} />
            <Route path="/duvidas" element={<CertificadoDuvidas />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/compra" element={<Compra />} />
            <Route path="/compra-finalizada" element={ProdutoCompra ? <CompraFinalizada /> : <Home />} />
        </Routes>
    )
}

export default Rotas
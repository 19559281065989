import React, { useContext, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import PlanosImagem from '../../components/Planos/PlanosImagem'
import Footer from '../../components/Footer/Footer'
import './style.css'
import homembusca from '../../images/Planos/homembusca.svg'
import beneficiocliente from '../../images/Busca/beneficiocliente.svg'
import confirmacao from '../../images/Busca/confirmacao.svg'
import ctatempo from '../../images/Busca/ctatempo.svg'
import deteccao from '../../images/Busca/deteccao.svg'
import escrituracao from '../../images/Busca/escrituracao.svg'
import manutencao from '../../images/Busca/manutencao.svg'
import notescritorio from '../../images/Busca/notescritorio.svg'
import notificacao from '../../images/Busca/notificacao.svg'
import robo from '../../images/Busca/robo.svg'
import vantagens from '../../images/Busca/vantagens.svg'
import { EmailContext } from "../../context/EmailContext";

function Busca() {
    const { setPdf } = useContext(EmailContext)
    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);;
    return (
        <>
            <Banner titulo={'e-Busca'} texto={'Simplificando a sua Rotina Fiscal com e-Busca NF-e: Automatize, Organize e Otimize!'} classe={'banner-busca'} origem={'Assunto: e-Busca'} nomeProduto={'e-Busca'} />
            <PlanosImagem imagem={homembusca} titulo={'Você já sentiu o peso da busca manual por notas fiscais eletrônicas, perdendo tempo valioso e utilizando leitores de código de barras desatualizados?'} texto={''} />
            <section className="razoes">
                <div className="elemento-centralizado">
                    <img src={robo} alt="Ícone de robo" style={{ width: '80px', height: '80px' }} />
                </div>
                <h2>Apresentamos o e-Busca NF-e, a sua ferramenta definitiva para agilizar e aprimorar suas operações fiscais.</h2>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={deteccao} alt="Ícone de detecção" style={{ width: '32px', height: '32px' }} />
                        <h3>Detecção Instantânea</h3>
                        <p>Quando os fornecedores emitem NF-e para o CNPJ do seu cliente, o e-Busca NF-e entra em ação.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={notificacao} alt="Ícone de notificação" style={{ width: '32px', height: '32px' }} />
                        <h3>Notificações em Tempo Real</h3>
                        <p>Seu cliente é imediatamente notificado sobre as emissões de NF-e.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={manutencao} alt="Ícone de manifestação" style={{ width: '32px', height: '32px' }} />
                        <h3>Manifestação do Destinatário</h3>
                        <p>Seu cliente realiza a Manifestação do Destinatário, garantindo a ciência da emissão e evitando cancelamentos indesejados.</p>
                    </div>
                </div>
                <div className="container-razoes">
                    <div className="card-razoes">
                        <img src={confirmacao} alt="Ícone de confirmação" style={{ width: '32px', height: '32px' }} />
                        <h3>Confirmação Automática</h3>
                        <p>Mercadoria recebida, seu cliente confirma a operação, ativando o download automático do arquivo digital XML diretamente da SEFAZ.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={notescritorio} alt="Ícone de escritório" style={{ width: '32px', height: '32px' }} />
                        <h3>Notificação ao Escritório</h3>
                        <p>O e-Busca NF-e informa o seu escritório sobre os documentos prontos para escrituração.</p>
                    </div>
                    <div className="card-razoes">
                        <img src={escrituracao} alt="Ícone de escrituração" style={{ width: '32px', height: '32px' }} />
                        <h3>Escrituração com um Clique</h3>
                        <p>Com apenas um clique, os arquivos digitais XML são integrados ao TOP Fiscal.</p>
                    </div>
                </div>
            </section>
            <section className="planos">
                <div className="busca">
                    <div className="content-busca">
                        <img src={beneficiocliente} alt="Ícone de benefício" style={{ width: '112px', height: '112px' }} />
                        <div>
                            <h2>Benefícios para Seu Cliente</h2>
                            <ul>
                                <li>Acompanhe em Tempo Real as NF-e de seus clientes.</li>
                                <li>Evite cancelamentos indesejados.</li>
                                <li>Download Automático dos Arquivos XML.</li>
                                <li>Organização Simples por CNPJ e Data.</li>
                                <li>Busca Fácil por Diversos Parâmetros.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="content-busca">
                        <div>
                            <h2>Vantagens para Seu Escritório</h2>
                            <ul>
                                <li>Recebimento Automático dos Arquivos Digitais XML.</li>
                                <li>Validação e Organização Automatizadas.</li>
                                <li>Importação Simplificada para Escrituração Fiscal.</li>
                                <li>Busca Rápida por Diversos Critérios.</li>
                            </ul>
                        </div>
                        <img src={vantagens} alt="Ícone de vantagens" style={{ width: '112px', height: '112px' }} />
                    </div>
                </div>
            </section>
            <section className="razoes">
                <h2>Não perca mais tempo com processos manuais e burocráticos.</h2>
                <p>O e-Busca NF-e é a ferramenta definitiva para simplificar e otimizar suas operações fiscais. Libere recursos e foque no que realmente importa: o crescimento do seu negócio. Experimente hoje mesmo e eleve sua gestão fiscal a um novo nível!</p>
                <div className="elemento-centralizado" style={{ marginBottom: '50px' }}>
                    <img src={ctatempo} alt='Ícone de tempo' style={{ width: '100px', height: '100px' }} />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Busca
import { useContext, useEffect } from 'react';
import InputMask from "react-input-mask";
import Botao from '../../components/Botao/Botao'
import './style.css'
import Footer from '../../components/Footer/Footer';
import formulario from '../../images/ExactusBank/formulario (1).png'
import CardBank from '../../components/Card/CardBank';
import antecipacao from '../../images/ExactusBank/antecipacao.svg'
import capital from '../../images/ExactusBank/capital.svg'
import domicilio from '../../images/ExactusBank/domicilio.svg'
import risco from '../../images/ExactusBank/risco.svg'
import regressao from '../../images/ExactusBank/regressao.svg'
import taxas from '../../images/ExactusBank/taxas.svg'
import conveniencia from '../../images/ExactusBank/conveniencia.svg'
import rapidez from '../../images/ExactusBank/rapidez.svg'
import lampada from '../../images/ExactusBank/lampada.svg'
import { NavLink } from 'react-router-dom';
import { EmailContext } from '../../context/EmailContext';
import Erro from "../../components/Alert/Erro";
import Sucesso from '../../components/Alert/Sucesso'
import Loading from '../../components/Loading/Loading'
import { validateEmail, validateCNPJ } from 'validations-br';

function ExactusBank() {
    const { cnpj, setCnpj, site, setSite,
        nomeDiretor, setNomeDiretor,
        emailDiretor, setEmailDiretor,
        telefoneContato, setTelefoneContato,
        numColaborador, setNumColaborador, ContatoCadastro, loading, msgErroEmail, setMsgErroEmail, msgSucessoEmail, setContabilidade, setPdf } = useContext(EmailContext)

    useEffect(() => {
        setPdf("")
        window.scrollTo(0, 0);
    }, []);
    const contato = () => {
        setContabilidade("Exactus Bank")
    }
    const verifica = () => {
        if (!validateCNPJ(cnpj)) {
            setMsgErroEmail("CNPJ inválido")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        } else if (!validateEmail(emailDiretor)) {
            setMsgErroEmail("E-mail inválido")
            setTimeout(() => {
                setMsgErroEmail("");
            }, 6000);
            return
        }
        ContatoCadastro()
    }
    return (
        <>
            {loading &&
                <Loading />
            }
            {msgErroEmail &&
                <Erro />
            }
            {msgSucessoEmail &&
                <Sucesso />
            }
            <div className="banner-padding-top">
                <section className="bank-banner">
                    <div className="container-banner-bank">
                        <div className="content-bank">
                            <h1>exactus bank
                                Soluções financeiras
                                para sua empresa</h1>
                            <p><b>Estruture seu capital de giro!</b> <br />
                                Conheça o nosso processo de Securitização captando recursos de médio e longo prazo para seus negócios.</p>
                            <a href="#cadastre">
                                <Botao texto={'Cadastre-se aqui >'} />
                            </a>
                        </div>
                    </div>
                </section>
            </div>
            <section className='section-cadastro-bank' id='cadastre'>
                <div className='cadastro-bank'>
                    <h2>Cadastre-se aqui</h2>
                    <p>Preencha este formulário, assim nosso Gerente Comercial entrará em contato!</p>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="cnpj">CNPJ da empresa</label>
                            <InputMask mask="99.999.999/9999-99" name="cnpj" id="cnpj" value={cnpj} onChange={(e) => setCnpj(e.target.value)}/>
                        </div>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="site">Site da Empresa</label>
                            <input type="text" name="site" id="site" value={site} onChange={(e) => setSite(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="diretor">Nome do Diretor Financeiro</label>
                            <input type="text" name="diretor" id="diretor" value={nomeDiretor} onChange={(e) => setNomeDiretor(e.target.value)} />
                        </div>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="emaildiretor">E-mail do Diretor Financeiro</label>
                            <input type="text" name="emaildiretor" id="emaildiretor" value={emailDiretor} onChange={(e) => setEmailDiretor(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="telefoneContato">Telefone de contato do RH</label>
                            <input type="phone" name="telefoneContato" id="telefoneContato" value={telefoneContato} onChange={(e) => setTelefoneContato(e.target.value)}/>
                        </div>
                        <div className="input" style={{ width: '48%' }}>
                            <label htmlFor="colaborador">Número de Colaboradores CLT</label>
                            <input type="text" name="colaborador" id="colaborador" value={numColaborador} onChange={(e) => setNumColaborador(e.target.value)} />
                        </div>
                    </div>
                    <div style={{ marginTop: '15px' }}>
                        <Botao texto={'Enviar informações'} onClick={() => verifica()} />
                    </div>
                </div>
                <img src={formulario} alt="Imagem de mulher" />
            </section>
            <section className='razoes'>
                <h2 style={{ marginTop: 0 }}>Conheça alguns de nossos Produtos Financeiros</h2>
                <CardBank imagem={antecipacao} titulo={'Antecipação de Recebíveis'} subtitulo={'Transforme suas Vendas a Prazo em Recursos à Vista!'} texto={'Antecipamos o pagamento de notas fiscais, duplicatas ou contratos que sua empresa receberia a curto, médio e longo prazo, tornando a empresa muito mais produtiva, pois você antecipa valores que tem a receber, otimizando tempo e reduzindo custos em um processo ágil e simples.'} vantangens={'Vantagens'} opcoes={['Operações isentas de IOF', 'Taxas competitivas', 'Possibilidade de personalização dos boletos']} />

                <CardBank imagem={capital} titulo={'Capital de Giro'} subtitulo={'Operações para Atender as Necessidades da sua Empresa!'} texto={'Análise do seu fluxo financeiro concedido com base em garantias complementares e acessórias com recebíveis, contratos, imóveis e outros.'} vantangens={'Vantagens'} opcoes={['Flexibilidade do capital de giro', 'Alongamento da necessidade do Capital de Giro em PMTs', 'Estruturação da operação buscando melhor atender sua necessidade']} />

                <CardBank imagem={domicilio} titulo={'Domicílio Bancário'} subtitulo={'Centralize suas Operações, Reduza Custos e Ganhe Segurança.'} texto={'Antecipe os recebíveis oriundos de contratos de fornecimento de mercadorias ou serviços, ou mesmo faturas de venda ou serviço, cujo pagamento é realizado exclusivamente por meio de crédito em conta de titularidade da empresa contratada, sem necessidade de emissão de boletos.'} vantangens={'Vantagens'} opcoes={['O cliente centraliza as operações, reduz custos de manutenção e aumenta a segurança nas transações', 'Operação simples e rápida']} />

                <CardBank imagem={risco} titulo={'Risco Sacado'} subtitulo={'Linha de Crédito Simplificada para Fornecedores.'} texto={'Atuando na cadeia produtiva de grandes empresas compradoras de bens ou serviços, identificamos os fornecedores que tem interesse em antecipar recursos, sem a necessidade de aprovação em linhas de crédito.'} vantangens={'Vantagens para quem Compra'} opcoes={['Conhecimento e experiência de nossos Especialistas', 'Possibilidade de automação através de Portal Eletrônico', 'Alongamento no prazo de pagamento de seus fornecedores']} vantagensFornece={'Vantagens para quem Fornece'} opcoesFornece={['Não compromete a linha de crédito da sua empresa', 'Operação sem incidência de IOF', 'Possibilidade de automação através de um Portal Eletrônico']} />

                <CardBank imagem={regressao} titulo={'Sem Direito de Regressão'} subtitulo={'Muito mais Vendas sem Risco de Crédito!'} texto={'Acrescente linhas de crédito adicionais para sua carteira de clientes, sem correr risco. Maximize suas vendas.'} vantangens={'Vantagens para quem Compra'} opcoes={['Maximização de suas vendas com mais limites de crédito', 'Operação sem risco de crédito (sem regresso)', 'Crédito sem garantias, sem IOF e sem endividamento bancário para seus clientes', 'Liberação de crédito ágil e sem burocracia, ponderando o histórico de vendas e recebimentos de cada cliente', 'Antecipação dos recebíveis no dia seguinte à emissão da NF', 'Possibilidade de extensão do prazo de vendas', 'Exclusivo para operações B2B']} />
                <div className="elemento-centralizado">
                    <NavLink to="/contato">
                        <Botao texto={'Fale com nosso consultor'} onClick={() => contato()}/>
                    </NavLink>
                </div>
            </section>
            <section className='banner-end-bank'>
                <div className='banner-end-bank-blue'>
                    <div className='banner-end-bank-div'>
                        <h2>EXACTUS BANK! <br />
                            Operações de Securitização Customizadas!
                        </h2>
                        <NavLink to="/contato">
                            <Botao texto={'Entre em contato com a gente'} onClick={() => contato()}/>
                        </NavLink>
                    </div>
                </div>
            </section>
            <section className='razoes'>
                <h2>Vantagens para seu Negócio!</h2>
                <div className="container-ecpf">
                    <div className="content-ecpf">
                        <img src={taxas} alt="Ícone de taxas" style={{width: '40px', height: '40px'}}/>
                        <h4>Taxas Menores</h4>
                        <p style={{ textAlign: 'center' }}>Taxas de juros menores que outras linhas de crédito.</p>
                    </div>
                    <div className="content-ecpf">
                        <img src={lampada} alt="Ícone de lâmpada" style={{width: '40px', height: '40px'}}/>
                        <h4>Orientação Financeira</h4>
                        <p style={{ textAlign: 'center' }}>Para que as suas conquistas  possam ser planejadas e realizadas de forma mais tranquila.</p>
                    </div>
                    <div className="content-ecpf">
                        <img src={conveniencia} alt="Ícone de conveniência" style={{width: '40px', height: '40px'}}/>
                        <h4>Conveniência</h4>
                        <p style={{ textAlign: 'center' }}>A contratação poderá ser presencial, por telefone ou assinatura digital.</p>
                    </div>
                    <div className="content-ecpf">
                        <img src={rapidez} alt="Ícone de rapidez" style={{width: '40px', height: '40px'}}/>
                        <h4>Rapidez</h4>
                        <p style={{ textAlign: 'center' }}>Após a contratação, o crédito pode entrar na conta no mesmo dia.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ExactusBank